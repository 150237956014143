<template>
    <div>
        <table>
            <tr>
                <th style="width:160px;" class="border p-1 text-center" rowspan="3">{{ rowName }}</th>
                <th style="width:180px; padding: 5px; 0" class="border text-center" v-for="(v, i) in weeks" :key="i">Week {{ v.week }}</th>
            </tr>
            <tr><th class="border text-center" style="padding: 5px; 0" v-for="(v, i) in weeks" :key="i">{{ v.start }}</th></tr>
            <tr><th class="border text-center" style="padding: 5px; 0" v-for="(v, i) in weeks" :key="i">{{ v.end }}</th></tr>
            <tr v-for="(r, i) in rowLabel" :key="i">
                <th class="border p-1 text-center">{{ r }}</th>
                <td v-for="(v, j) in weeks" :key="j" class="border p-0 align-top">
                <template v-if="list[v.week + r]">
                    <div class="d-flex border-bottom font-weight-bold bg-success text-white">
                    <div class="flex-grow-1 pl-1">總時數 : </div><div class="text-right pr-1" style="width:80px">{{ list[v.week + r].total }} h</div>
                    </div>
                    <div v-for="(hour, ticket) in list[v.week + r].task_list" :key="ticket" class="d-flex">
                    <div class="flex-grow-1 d-flex pl-1"><a :href="`https://timesitteam.atlassian.net/browse/${ticket}`" target="ticket">{{ ticket }}</a></div>
                    <div role="button" @click="$emit('loadInfo', ticket)" class="text-right pr-1" style="width:80px" v-if="hour != '-'">{{ hour }} h</div>
                    <div role="button" @click="$emit('loadInfo', ticket)" class="text-right text-white pr-1 bg-warning" style="width:80px" v-else><i>無時數</i></div>
                    </div>
                </template>
                </td>
            </tr>
        </table>
        <br>
        <hr>
        <h3>簡表</h3>
        <table>
            <tr>
                <th style="width:360px; padding: 5px;" class="border text-center" rowspan="3">{{ rowName }}</th>
                <th style="width:100px; padding: 5px; 0" class="border text-center" v-for="(v, i) in weeks" :key="i">Week {{ v.week }}</th>
            </tr>
            <tr><td class="border text-center" style="padding: 5px; 0" v-for="(v, i) in weeks" :key="i">{{ v.start }}</td></tr>
            <tr><td class="border text-center" style="padding: 5px; 0" v-for="(v, i) in weeks" :key="i">{{ v.end }}</td></tr>
            <tr v-for="(r, i) in rowLabel" :key="i">
                <th class="border text-center" style="padding: 5px;">{{ r }}</th>
                <td v-for="(v, j) in weeks" :key="j" class="border text-right" style="padding: 5px;">{{ list[v.week + r] ? list[v.week + r].total : "" }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
    import moment from 'moment'
    export default {
        components: {
            moment
        },
        props: {
            rowName: String,
            weeks: Array,
            rowLabel: Array,
            list: Object,
        },
        methods: {

        }
    }
</script>