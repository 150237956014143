<template>
    <table class="w-100">
    <tr>
        <th style="width:120px; padding:5px" class="border bg-light">Requester</th>
        <th style="width:180px; padding:5px" class="border bg-light">System Module</th>
        <th style="width:120px; padding:5px" class="border bg-light">Customer</th>
        <th style="width:400px; padding:5px" class="border bg-light">Summary</th>
        <th style="width:90px; padding:5px" class="border bg-light">Key</th>
        <th style="width:80px; padding:5px" class="border bg-light text-center">Priority</th>
        <th style="width:150px; padding:5px" class="border bg-light text-center">Status</th>
        <th style="width:90px; padding:5px" class="border bg-light text-center">ETA</th>
        <th style="width:90px; padding:5px" class="border bg-light text-center">Go-live</th>
        <th style="width:60px; padding:5px" class="border bg-light text-center">PM<br>時數</th>
        <th style="width:60px; padding:5px" class="border bg-light text-center">Dev<br>時數</th>
        <th style="padding:5px" class="border bg-light">Remark</th>
    </tr>
    <tr v-for="(n, j) in task.sort((a, b) => sortStatus(a, b))" :key="j">
        <td style="padding:5px" class="border">{{ n.requester }}</td>
        <td style="padding:5px" class="border">{{ n.system_module }}</td>
        <td style="padding:5px" class="border">{{ n.customer }}</td>
        <td style="padding:5px" class="border">{{ n.fields.summary }}</td>
        <td style="padding:5px" class="border"><a :href="`https://timesitteam.atlassian.net/browse/${n.key}`" target="ticket">{{ n.key }}</a></td>
        <td style="padding:5px" class="border text-center" :class="getClassForPriority(n.fields.priority)">{{ n.fields.priority.name === 'P0' ? 'Top' : n.fields.priority.name === 'P1' ? 'Normal' : 'Low' }}</td>
        <td style="padding:5px" :class="getClassForStatus(n.fields.status)" class="border text-center">
            {{ n.fields.status.name }}
            <template v-if="n.fields.status.name === 'To Do'"><br>( {{ getAge(n.status_time.to_do) }} )</template>
            <template v-if="n.fields.status.name === 'Requirement'"><br>( {{ getAge(n.status_time.requirement) }} )</template>
            <template v-if="n.fields.status.name === 'In Progress'"><br>( {{ getAge(n.status_time.in_progress) }} )</template>
            <template v-if="n.fields.status.name === 'Dev Done'"><br>( {{ getAge(n.status_time.dev_done) }} )</template>
            <template v-if="n.fields.status.name === 'Ready for UAT'"><br>( {{ getAge(n.status_time.ready_for_uat) }} )</template>
            <template v-if="n.fields.status.name === 'Waiting for Go Live'"><br>( {{ getAge(n.status_time.waiting_for_go_live) }} )</template>
        </td>
        <td style="padding:5px" class="border text-center">{{ n.fields.duedate }}</td>
        <td style="padding:5px" class="border text-center">{{ n.go_live_date }}</td>
        <td style="padding:5px" class="border text-center">{{ n.pm_man_hour }}</td>
        <td style="padding:5px" class="border text-center">{{ n.actual_man_hour }}</td>
        <td style="padding:5px" class="border">
        <p v-for="(r, k) in n.remark" :key="k" class="p-0 m-0" :class="getRemarkClass(r.time)">
            <i>[{{ r.time.substring(0, 10).replace("T", " ") }}]</i> {{ r.data.toString }}
        </p>
        </td>
    </tr>
    </table>
</template>

<script>
    import moment from 'moment'
    export default {
        components: {
            moment
        },
        props: {
            task: Array
        },
        methods: {
            getClassForStatus(status) {
                if (status.name == 'UAT Fail')
                    return ['bg-danger', 'text-white'];
                if (status.name == 'Waiting for Go Live')
                    return ['bg-info', 'text-white'];
                if (status.statusCategory.name == 'In Progress')
                    return ['bg-success', 'text-white'];
                if (status.statusCategory.name == 'To Do')
                    return ['bg-light', 'text-dark'];
                if (status.name == 'Cancelled')
                    return ['bg-secondary', 'text-white'];
                return ['bg-dark', 'text-white'];
            },
            getClassForPriority(priority) {
                if (priority.name === 'P0')
                    return ['bg-primary', 'text-white'];
                if (priority.name === 'P1')
                    return ['bg-white', 'text-dark'];
                if (priority.name === 'P2')
                    return ['bg-light', 'text-dark'];
                return ['bg-dark', 'text-white'];
            },
            getRemarkClass(t) {
                const d = Date.parse(t);
                if (Date.now() - d <= 5 * 24 * 60 * 60 * 1000)
                    return ['bg-warning', 'text-white'];
            },
            sortStatus(a, b) {
                if (a.fields.status.name !== b.fields.status.name) {
                    if (a.fields.status.name == 'Cancelled')
                        return 1
                    if (b.fields.status.name == 'Cancelled')
                        return -1
                    if (a.fields.status.name == 'Done')
                        return 1
                    if (b.fields.status.name == 'Done')
                        return -1
                }
                
                if (a.fields.priority.name !== b.fields.priority.name) {
                    if (a.fields.priority.name == 'P0')
                        return -1
                    if (b.fields.priority.name == 'P0')
                        return 1
                    if (a.fields.priority.name == 'P1')
                        return -1
                    if (b.fields.priority.name == 'P1')
                        return 1
                }

                if (a.fields.status.name !== b.fields.status.name) {
                    if (a.fields.status.name == 'Requirement')
                        return 1
                    if (b.fields.status.name == 'Requirement')
                        return -1
                    if (a.fields.status.statusCategory.name == 'To Do')
                        return 1
                    if (b.fields.status.statusCategory.name == 'To Do')
                        return -1
                    
                    if (a.fields.status.name == 'Waiting for Go Live')
                        return 1
                    if (b.fields.status.name == 'Waiting for Go Live')
                        return -1
                    if (a.fields.status.name == 'UAT Fail')
                        return 1
                    if (b.fields.status.name == 'UAT Fail')
                        return -1
                    if (a.fields.status.name == 'Ready for UAT')
                        return 1
                    if (b.fields.status.name == 'Ready for UAT')
                        return -1
                    if (a.fields.status.name == 'Dev Done')
                        return 1
                    if (b.fields.status.name == 'Dev Done')
                        return -1
                }
                
                return a.fields.created.localeCompare(b.fields.created);
            },
            getAge(t) {
                let d = moment().diff(moment(t, "YYYY-MM-DD HH:mm:ss"));
                return Math.floor(d / 60 / 60 / 1000 / 24) + "d " + moment.utc(d - d / 60 / 60 / 1000 / 24).format("HH") + "h";
            }
        }
    }
</script>