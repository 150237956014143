<template>
  <b-overlay :show="loading" rounded="sm">
    <template #overlay>
      <b-progress :value="progress" :max="total" show-progress animated height="2rem" style="width:800px"></b-progress>
    </template>
    <b-card>
      <div class="d-flex">
        <div class="flex-grow-1"><button type="button" class="btn" :class="{'btn-primary':hide_completed, 'btn-success':!hide_completed}" @click="hide_completed = !hide_completed">{{ hide_completed ? "Hide Done / 隠藏完成任務" : "Show Done / 顯示完成任務" }}</button></div>
        <div>{{ update_since }}</div>
      </div>
      <b-tabs align="left">
        <b-tab title="Pending Task">
          <div class="d-flex flex-column">
            <div v-for="(p, i) in ['P0', 'P1', 'P2']" :key="i" class="my-2">
              <h2>Priority : {{ p }}</h2>
              <table>
                <tr>
                  <th style="width:180px; padding:5px;" class="border">Status</th>
                  <th style="width:110px; padding:5px;" class="border text-center" v-for="(w, k) in 15" :key="k">{{ w - 1 }} days</th>
                  <th style="width:150px; padding:5px;" class="border text-center">14 days +</th>
                </tr>
                <tr v-for="(s, j) in ['Requirement', 'To Do', 'In Progress', 'Dev Done', 'Ready for UAT', 'UAT Fail', 'Waiting for Go Live']" :key="j">
                  <th style="padding:5px;" class="border">{{s}}</th>
                  <td style="padding:5px;" class="border align-top text-center" v-for="(w, k) in 15" :key="k">
                    <div v-for="(t, l) in pending_day_stat[p + s + (w - 1)]" :key="l" v-if="pending_day_stat[p + s + (w - 1)]">
                      <a :href="`https://timesitteam.atlassian.net/browse/${t.key}`" target="ticket">{{ t.key }}</a> 
                    </div>
                  </td>
                  <td style="padding:5px;" class="border align-top text-center">
                    <div v-if="s === 'Requirement' && p !== 'P0'">
                      <i>[ hide {{ pending_day_stat[p + s + '14+'] ? pending_day_stat[p + s + '14+'].length : 0 }} tasks ]</i>
                    </div>
                    <div v-for="(t, l) in pending_day_stat[p + s + '14+']" :key="l" v-else-if="pending_day_stat[p + s + '14+']">
                      <a :href="`https://timesitteam.atlassian.net/browse/${t.key}`" target="ticket">{{ t.key }}</a> / {{ getAgeWithStatus(t) }}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </b-tab>
        <b-tab title="所有未完成">
          <RealtimeTable :task="open_task_list.filter(e => true)" />
        </b-tab>
        <b-tab title="進度 - Epic">
          <div class="d-flex w-100 flex-column">
            <div v-for="(m, i) in task_list.filter(n => n.fields.issuetype.name == 'Epic').sort((a, b) => a.key.localeCompare(b.key))" :key="i" class="my-1"
              v-if="task_list.filter(n => n.fields.parent != null && n.fields.parent.key == m.key && n.fields.status.name != 'Cancelled').filter(n => hide_completed == false || n.fields.status.statusCategory.name != 'Done').length > 0"
            >
              <h3><a :href="`https://timesitteam.atlassian.net/browse/${m.key}`" target="ticket">{{ m.key }}</a> / {{ m.fields.summary }} <template v-if="m.fields.assignee">/ {{ m.fields.assignee.displayName }}</template></h3>
              <RealtimeTable :task="task_list.filter(n => n.fields.parent != null && n.fields.parent.key == m.key && n.fields.status.name != 'Cancelled').filter(n => hide_completed == false || n.fields.status.statusCategory.name != 'Done')" />
            </div>
          </div>
        </b-tab>
        <b-tab title="進度 - 請求者">
          <div class="d-flex w-100 flex-column">
            <div v-for="(m, i) in task_category.requester" :key="i" class="my-1"
              v-if="non_epic_task_list.filter(n => n.requester !== '' && n.requester === m && n.fields.status.name != 'Cancelled').filter(n => hide_completed == false || n.fields.status.statusCategory.name != 'Done').length > 0"
            >
              <h3>{{ m }}</h3>
              <RealtimeTable :task="non_epic_task_list.filter(n => n.requester !== '' && n.requester === m && n.fields.status.name != 'Cancelled').filter(n => hide_completed == false || n.fields.status.statusCategory.name != 'Done')" />
            </div>
          </div>
        </b-tab>

        <b-tab title="進度 - 系統">
          <div class="d-flex w-100 flex-column">
            <div v-for="(m, i) in task_category.system_module" :key="i" class="my-1"
              v-if="non_epic_task_list.filter(n => n.system_module !== '' && n.system_module === m).filter(n => hide_completed == false || n.fields.status.statusCategory.name != 'Done').length > 0"
            >
              <h3>{{ m }}</h3>
              <RealtimeTable :task="non_epic_task_list.filter(n => n.system_module !== '' && n.system_module === m).filter(n => hide_completed == false || n.fields.status.statusCategory.name != 'Done')" />
            </div>
          </div>
        </b-tab>

        <b-tab title="進度 - 客戶">
          <div class="d-flex w-100 flex-column">
            <div v-for="(m, i) in task_category.customer" :key="i" class="my-1"
              v-if="non_epic_task_list.filter(n => n.customer !== '' && n.customer === m).filter(n => hide_completed == false || n.fields.status.statusCategory.name != 'Done').length > 0"
            >
              <h3>{{ m }}</h3>
              <RealtimeTable :task="non_epic_task_list.filter(n => n.customer !== '' && n.customer === m).filter(n => hide_completed == false || n.fields.status.statusCategory.name != 'Done')" />
            </div>
          </div>
        </b-tab>

        <!-- ////// -->

        <!--
        <b-tab title="Watching">
          <RealtimeTable :task="task_list.filter(e => e.fields.watches.isWatching && e.fields.issuetype.name != 'Epic')" />
        </b-tab>
        -->
        <b-tab title="工時 - 開發">
          <RealtimeWorkingHour :list="task_hour.data_members" :rowLabel="task_hour.members" rowName="開發" :weeks="task_hour.weeks" @loadInfo="loadInfo($event)" />
        </b-tab>
        
        <b-tab title="工時 - 請求者">
          <RealtimeWorkingHour :list="task_hour.data_requesters" :rowLabel="task_hour.requesters" rowName="需求者" :weeks="task_hour.weeks" @loadInfo="loadInfo($event)" />
        </b-tab>

        <b-tab title="工時 - 客戶">
          <RealtimeWorkingHour :list="task_hour.data_customers" :rowLabel="task_hour.customers" rowName="客戶" :weeks="task_hour.weeks" @loadInfo="loadInfo($event)" />
        </b-tab>

        <b-tab title="無Epic">
          <RealtimeTable :task="non_epic_task_list.filter(e => !e.fields.parent).filter(n => hide_completed == false || n.fields.status.statusCategory.name != 'Done')" />
        </b-tab>

        <b-tab title="未填請求者">
          <RealtimeTable :task="non_epic_task_list.filter(e => e.requester === '').filter(n => hide_completed == false || n.fields.status.statusCategory.name != 'Done')" />
        </b-tab>

        <b-tab title="未填系統">
          <RealtimeTable :task="non_epic_task_list.filter(e => e.system_module === '').filter(n => hide_completed == false || n.fields.status.statusCategory.name != 'Done')" />
        </b-tab>

        <b-tab title="未填客戶">
          <RealtimeTable :task="non_epic_task_list.filter(e => e.customer === '').filter(n => hide_completed == false || n.fields.status.statusCategory.name != 'Done')" />
        </b-tab>

        <b-tab title="未填工時">
          <div class="d-flex w-100 flex-column">
            <div v-for="(m, i) in task_category.member" :key="i" class="my-1"
              v-if="non_epic_task_list.filter(
                  n => n.fields.assignee != null 
                  && n.fields.assignee.displayName === m 
                  && n.actual_man_hour == null
                  && (n.fields.status.name == 'Done' || n.fields.status.name == 'Waiting for Go Live' || n.fields.status.name == 'Ready for UAT')).length > 0"
            >
              <h3>{{ m }}</h3>
              <RealtimeTable :task="non_epic_task_list.filter(
                  n => n.fields.assignee != null 
                  && n.fields.assignee.displayName === m 
                  && n.actual_man_hour == null
                  && (n.fields.status.name == 'Done' || n.fields.status.name == 'Waiting for Go Live' || n.fields.status.name == 'Ready for UAT'))" />
            </div>
            <div v-for="(m, i) in task_category.member" :key="i" class="my-1 bg-primary text-white p-1"
              v-if="non_epic_task_list.filter(
                  n => n.fields.assignee != null 
                  && n.fields.assignee.displayName === m 
                  && n.actual_man_hour == null
                  && (n.fields.status.name == 'Done' || n.fields.status.name == 'Waiting for Go Live' || n.fields.status.name == 'Ready for UAT')).length > 0"
            >
              <p>--- {{ m }} ---</p>
              <lu>
              <li v-for="(e, i) in non_epic_task_list.filter(
                  n => n.fields.assignee != null 
                  && n.fields.assignee.displayName === m 
                  && n.actual_man_hour == null
                  && (n.fields.status.name == 'Done' || n.fields.status.name == 'Waiting for Go Live' || n.fields.status.name == 'Ready for UAT'))" :key="i">
                  {{ e.key }} - {{ e.fields.summary }}
              </li>
              </lu>
            </div>

          </div>
        </b-tab>
        <b-tab title="Changelog">
          <table>
            <tr>
              <th style="width:100px; padding:5px;" class="border text-center">Key</th>
              <th style="width:140px; padding:5px;" class="border text-center">Update Time</th>
              <th style="width:150px; padding:5px;" class="border text-center">User</th>
              <th style="width:150px; padding:5px;" class="border text-center">Filed</th>
              <th style="padding:5px;" class="border text-center">From</th>
              <th style="padding:5px;" class="border text-center">To</th>
            </tr>
            <template v-for="(c, i) in changelog_list">
              <tr v-if="c.items.length === 1">
                <td style="padding:5px;" class="border align-top text-center"><a :href="`https://timesitteam.atlassian.net/browse/${c.key}`" target="ticket">{{ c.key }}</a></td>
                <td style="padding:5px;" class="border align-top text-center"><span :title="c.created.substring(0, 16).replace('T', ' ')">{{ getSinceNow(c.created) }}</span></td>
                <td style="padding:5px;" class="border align-top text-center">{{ c.author }}</td>
                <td style="padding:5px;" class="border align-top text-center">{{ c.items[0].field }}</td>
                <td style="padding:5px;" class="border align-top" v-if="['summary', 'description', 'Comment', 'Remark'].includes(c.items[0].field)" colspan="2"><b>From:</b><br>{{ c.items[0].from }}<hr><b>To:</b><br>{{ c.items[0].to }}</td>
                <td style="padding:5px;" class="border align-top" v-if="!['summary', 'description', 'Comment', 'Remark'].includes(c.items[0].field)">{{ c.items[0].from }}</td>
                <td style="padding:5px;" class="border align-top" v-if="!['summary', 'description', 'Comment', 'Remark'].includes(c.items[0].field)">{{ c.items[0].to }}</td>
              </tr>
              <template v-else>
                <tr>
                  <td style="padding:5px;" class="border align-top text-center" :rowspan="c.items.length"><a :href="`https://timesitteam.atlassian.net/browse/${c.key}`" target="ticket">{{ c.key }}</a></td>
                  <td style="padding:5px;" class="border align-top text-center" :rowspan="c.items.length"><span :title="c.created.substring(0, 16).replace('T', ' ')">{{ getSinceNow(c.created) }}</span></td>
                  <td style="padding:5px;" class="border align-top text-center" :rowspan="c.items.length">{{ c.author }}</td>
                  <td style="padding:5px;" class="border align-top text-center">{{ c.items[0].field }}</td>
                  <td style="padding:5px;" class="border align-top" v-if="['summary', 'description', 'Comment', 'Remark'].includes(c.items[0].field)" colspan="2">{{ c.items[0].from }}<br>{{ c.items[0].to }}</td>
                  <td style="padding:5px;" class="border align-top" v-if="!['summary', 'description', 'Comment', 'Remark'].includes(c.items[0].field)">{{ c.items[0].from }}</td>
                  <td style="padding:5px;" class="border align-top" v-if="!['summary', 'description', 'Comment', 'Remark'].includes(c.items[0].field)">{{ c.items[0].to }}</td>
                </tr>
                <tr v-for="(it, i) in c.items" :key="i" v-if="i > 0">
                  <td style="padding:5px;" class="border align-top text-center">{{ it.field }}</td>
                  <td style="padding:5px;" class="border align-top" v-if="['summary', 'description', 'Comment', 'Remark'].includes(it.field)" colspan="2"><b>From:</b><br>{{ it.from }}<br><b>To:</b><br>{{ it.to }}</td>
                  <td style="padding:5px;" class="border align-top" v-if="!['summary', 'description', 'Comment', 'Remark'].includes(it.field)">{{ it.from }}</td>
                  <td style="padding:5px;" class="border align-top" v-if="!['summary', 'description', 'Comment', 'Remark'].includes(it.field)">{{ it.to }}</td>
                </tr>
              </template>
            </template>
          </table>
        </b-tab>
<!--
        <b-tab title="To Do 的任務">
          <div class="border-botton border-top p-1 d-flex">
            <div style="width:100px" class="font-weight-bold">Ticket</div>
            <div style="width:200px" class="font-weight-bold">Assignee</div>
            <div style="width:120px" class="font-weight-bold text-center">Priority</div>
            <div style="width:200px" class="font-weight-bold text-center">Status</div>
            <div style="width:200px" class="font-weight-bold">To Do</div>
            <div style="width:200px" class="font-weight-bold">Age</div>
            <div class="flex-grow-1 font-weight-bold">Summary</div>
          </div>
          <div v-for="(v, k) in task_list" :key="k" class="border-top p-1 d-flex" v-if="v.fields.status.id == c[v.project].status_to_do && v.status_time.to_do">
            <div style="width:100px"><a :href="`https://timesitteam.atlassian.net/browse/${v.key}`" target="ticket">{{ v.key }}</a></div>
            <div style="width:200px"><img style="width:32px" :src="v.fields.assignee.avatarUrls['48x48']" v-if="v.fields.assignee"><span class="pl-1" v-if="v.fields.assignee">{{ v.fields.assignee.displayName }}</span></div>
            <div style="width:120px" class="text-center">{{ v.fields.priority.name }}</div>
            <div style="width:200px" class="text-center">{{ v.fields.status.name }}</div>
            <div style="width:200px">{{ v.status_time.to_do }}</div>
            <div style="width:200px">{{ getAge(v.status_time.to_do) }}</div>
            <div class="flex-grow-1">{{ v.fields.summary }}</div>
          </div>
        </b-tab>
        <b-tab title="Debug">
          <b-table responsive="sm" :items="items" :sticky-header="windowHeight - 280 + 'px'" :no-border-collapse="false">
            <template #cell(key)="data">
              <div class="d-flex w-100 flex-column">
                <div class="p-0 w-100"><a :href="`https://timesitteam.atlassian.net/browse/${data.item.key.key}`" target="ticket">{{ data.item.key.key }}</a></div>
                <div class="p-0 w-100">{{ data.item.key.summary }}</div>
              </div>
            </template>
            <template #cell(value)="data">
              <div v-for="(v, i) in data.item.value" :key="i" class="d-flex w-100">
                <div style="width: 200px;" class="border p-1">{{ v.status }}</div><div style="width:200px" class="flex-grow-1 border p-1">{{ v.time.substring(0, 19).replace('T', ' ') }}</div>
              </div>
            </template>
            <template #cell(assignee)="data">
              <img style="width:32px" :src="data.item.assignee.avatarUrls['48x48']" v-if="data.item.assignee">
            </template>
            <template #cell(status)="data">
              <div class="bg-primary text-white text-center" v-if="data.item.status == 'Requirement'">{{ data.item.status }}</div>
              <div class="bg-warning text-white text-center" v-else-if="data.item.status == 'To Do'">{{ data.item.status }}</div>
              <div class="bg-success text-white text-center" v-else-if="data.item.status == 'In Progress'">{{ data.item.status }}</div>
              
              <div class="bg-secondary text-white text-center" v-else-if="data.item.status == 'Done'">{{ data.item.status }}</div>
              <div class="bg-black text-white text-center" v-else>{{ data.item.status }}</div>
            </template>
            <template #cell(start_cal_time)="data">
              <div class="bg-danger text-white text-center" v-if="data.item.start_cal_time !== data.item.in_progress">{{ data.item.start_cal_time }}</div>
              <div class="bg-success text-white text-center" v-else>{{ data.item.start_cal_time }}</div>
            </template>
            <template #cell(end_cal_time)="data">
              <div class="bg-danger text-white text-center" v-if="data.item.end_cal_time !== data.item.dev_done">{{ data.item.end_cal_time }}</div>
              <div class="bg-success text-white text-center" v-else>{{ data.item.end_cal_time }}</div>
            </template>
            <template #cell(diff_hour)="data">
              <div class="bg-danger text-white text-center" v-if="data.item.diff_hour !== data.item.diff">{{ data.item.diff_hour }}</div>
              <div class="bg-success text-white text-center" v-else>{{ data.item.diff_hour }}</div>
            </template>
            <template #cell(contribution_map)="data">
              <div v-for="(v, i) in data.item.contribution_map" :key="i" class="d-flex w-100">
                <div style="width:110px" class="border text-center px-1 py-0">{{ i }}</div>
                <div style="width:80px" class="border text-right px-1 py-0">{{ Math.round(v.task_time / 60 / 60 / 1000 * 100) / 100 }} h</div>
                <div style="width:80px" class="border text-right px-1 py-0">{{ data.item.actual_man_hour > 0 ? Math.round(v.actual_man_hour * 100) / 100 + " h" : "-" }}</div>
              </div>
            </template>
          </b-table>
        </b-tab>
-->
      </b-tabs>
    </b-card>
    <!-- modal Scrolling Content inside Modal-->
    <b-modal ref="modal-info" scrollable title="任務明細" ok-only ok-title="OK" cancel-variant="outline-secondary" size="xl">
      <b-card-text>
        <div class="d-flex w-100 flex-column" v-if="selectedTask">
          <div class="d-flex w-100"><div style="width:150px" class="p-1 border">任務</div><div class="flex-grow-1 p-1 border">{{ selectedTask.key }}</div></div>
          <div class="d-flex w-100"><div style="width:150px" class="p-1 border">標題</div><div class="flex-grow-1 p-1 border">{{ selectedTask.fields.summary }}</div></div>
          <div class="d-flex w-100"><div style="width:150px" class="p-1 border">Changelog</div>
            <div class="flex-grow-1 p-1 border d-flex flex-column">
              <div class="d-flex w-100">
                <div class="text-center border p-1" style="width:250px">操作人員</div>
                <div class="text-center border p-1" style="width:200px">狀態</div>
                <div class="text-center border p-1" style="width:180px">時間</div>
                <div class="text-center border p-1 flex-grow-1">備註</div>
              </div>
              <div v-for="(v, i) in selectedTask.changelog" :key="i" class="d-flex w-100">
                <div class="text-center border p-1" style="width:250px">
                  <img style="width:24px" :src="v.author.avatarUrls['48x48']" v-if="v.author"><span class="pl-1" v-if="v.author">{{ v.author.displayName }}</span>
                </div>
                <div class="text-center border p-1" style="width:200px">
                  {{ v.status_string }}
                </div>
                <div class="text-center border p-1" style="width:180px">
                  {{ v.time.substring(0, 19).replace('T', ' ') }}
                </div>
                <div class="text-center border p-1 flex-grow-1">
                  <template v-if="v.status == 10105 && selectedTask.status_time.in_progress == v.time.substring(0, 19).replace('T', ' ')">
                    <div v-if="selectedTask.status_time.in_progress != selectedTask.status_time.start_cal_time" class="bg-warning text-white"><i>(非工作時間，假設開始時間為 {{selectedTask.status_time.start_cal_time}})</i></div>
                    <div v-else>開發開始</div>
                  </template>
                  <template v-if="v.status == 10107 && selectedTask.status_time.dev_done == v.time.substring(0, 19).replace('T', ' ')">
                    <div v-if="selectedTask.status_time.dev_done != selectedTask.status_time.end_cal_time" class="bg-warning text-white"><i>(非工作時間，假設結束時間為 {{selectedTask.status_time.end_cal_time}})</i></div>
                    <div v-else>開發結束</div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex w-100">
            <div style="width:150px" class="p-1 border">實制時數</div>
            <div class="flex-grow-1 p-1 border" v-if="selectedTask.actual_man_hour">{{ selectedTask.actual_man_hour }} 小時</div>
            <div class="flex-grow-1 p-1 border" v-else><i>(沒有輸入時數)</i></div>
          </div>
          <div class="d-flex w-100">
            <div style="width:150px" class="p-1 border">攤分時數</div>
            <div class="flex-grow-1 p-1 border">
              <div class="d-flex w-100">
                <div style="width:130px" class="border text-center p-1">上班日期</div>
                <div style="width:180px" class="border text-right p-1">停留在狀態時數</div>
                <div style="width:200px" class="border text-right p-1">實制時數，按比例攤分</div>
              </div>
              <div v-for="(v, i) in selectedTask.status_time.contribution_map" :key="i" class="d-flex w-100">
                <div style="width:130px" class="border text-center p-1">{{ i }}</div>
                <div style="width:180px" class="border text-right p-1">{{ Math.round(v.task_time / 60 / 60 / 1000 * 100) / 100 }} 小時</div>
                <div style="width:200px" class="border text-right p-1" v-if="selectedTask.actual_man_hour > 0">{{Math.round(v.actual_man_hour * 100) / 100}} 小時</div>
                <div style="width:200px" class="border text-right p-1" v-else><i>(沒有輸入時數)</i></div>
              </div>
            </div>
          </div>
          <!-- <div class="w-100 border p-1">{{ selectedTask }}</div> -->
        </div>
      </b-card-text>
    </b-modal>
  </b-overlay>
</template>

<script>
import { BCard, BTable, BTabs, BTab, BOverlay, BButton, BSpinner, BProgress, BModal, BCardText } from 'bootstrap-vue'
import moment from 'moment'
import { fetcher, FetchMethod } from "@/libs/axios";
import apis from "@configs/apis";
import RealtimeTable from "./RealtimeTable.vue"
import RealtimeWorkingHour from "./RealtimeWorkingHour.vue"

export default {
  components: {
    BCard,
    BTable,
    BTabs,
    BTab,
    BOverlay,
    BButton,
    BSpinner,
    BProgress,
    BModal,
    BCardText,
    moment,
    RealtimeTable,
    RealtimeWorkingHour
  },
  computed: {
    items () {
      return this.task_list.map(e => {
        return {key: { key:e.key, summary:e.fields.summary},
                requirement         : e.status_time.requirement,
                to_do               : e.status_time.to_do,
                in_progress         : e.status_time.in_progress,
                start_cal_time      : e.status_time.start_cal_time,
                dev_done            : e.status_time.dev_done,
                end_cal_time        : e.status_time.end_cal_time,
                diff                : e.status_time.diff,
                diff_hour           : e.status_time.diff_hour,
                ready_for_uat       : e.status_time.ready_for_uat,
                waiting_for_go_live : e.status_time.waiting_for_go_live,
                done                : e.status_time.done,
                actual_man_hour     : e.actual_man_hour,
                assignee            : e.fields.assignee,
                status              : e.fields.status.name,
                contribution_map    : e.status_time.contribution_map
              };
      });
    },
  },
  data () {
    return {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      loading: false,
      lastupdate: null,
      update_since: 'Out Date',
      progress: 0,
      total: 0,
      task_list: [],
      open_task_list: [],
      non_epic_task_list: [],
      pending_day_stat: {},
      changelog_list: [],
      hide_completed: false,
      task_hour: {
        weeks:[],
        members:[],
        data_members: {},
        requesters:[],
        data_requesters: {},
        customers:[],
        data_customers: {},
      },
      task_category: {
        system_module:[],
        requester:[],
        customer:[],
        member:[]
      },
      selectedTask: null,
      baseFields:["summary", "status", "assignee", "reporter", "issuetype", "created", "priority", "duedate", "parent", "watches", "duedate"],
      c: {
        "KEOM" : { // 10035
          "actual_man_hour"            : "customfield_10102",
          "pm_man_hour"                : "customfield_10132",
          "system_module"              : "customfield_10123",
          "customer"                   : "customfield_10135",
          "requester"                  : "customfield_10122",
          "go_live_date"               : "customfield_10124",
          "remark"                     : "customfield_10125",
          "status_requirement"         : "10144",
          "status_to_do"               : "10104",
          "status_in_progress"         : "10105",
          "status_dev_done"            : "10107",
          "status_ready_for_uat"       : "10145",
          "status_waiting_for_go_live" : "10108",
          "status_done"                : "10106",
          "effort_start"               : "10105",
          "effort_end"                 : "10107"
        },
        "KSEP" : { // 10043
          "actual_man_hour"            : "customfield_10116",
          "pm_man_hour"                : "customfield_10131",
          "system_module"              : "customfield_10120",
          "customer"                   : "customfield_10133",
          "requester"                  : "customfield_10119",
          "go_live_date"               : "customfield_10121",
          "remark"                     : "customfield_10118",
          "status_requirement"         : "10152",
          "status_to_do"               : "10149",
          "status_in_progress"         : "10150",
          "status_dev_done"            : "10153",
          "status_ready_for_uat"       : "10155",
          "status_waiting_for_go_live" : "10157",
          "status_done"                : "10151",
          "status_cancelled"           : "10158",
          "effort_start"               : "10150",
          "effort_end"                 : "10153"
        },
        "KENG" : { // 10044
          "actual_man_hour"            : "customfield_10117",
          "pm_man_hour"                : "customfield_10130",
          "system_module"              : "customfield_10127",
          "customer"                   : "customfield_10134",
          "requester"                  : "customfield_10126",
          "go_live_date"               : "customfield_10128",
          "remark"                     : "customfield_10129",
          "status_requirement"         : "10162",
          "status_to_do"               : "10159",
          "status_in_progress"         : "10160",
          "status_dev_done"            : "10163",
          "status_ready_for_uat"       : "10165",
          "status_waiting_for_go_live" : "10167",
          "status_done"                : "10161",
          "status_cancelled"           : "10168",
          "effort_start"               : "10160",
          "effort_end"                 : "10163"
        },
        "AZEU" : { // 10040
          "actual_man_hour"            : "customfield_10101",
          "pm_man_hour"                : "customfield_10139",
          "system_module"              : "customfield_10137",
          "customer"                   : "customfield_10138",
          "requester"                  : "customfield_10136",
          "go_live_date"               : "customfield_10140",
          "remark"                     : "customfield_10141",
          "status_requirement"         : "0",
          "status_to_do"               : "10124",
          "status_in_progress"         : "10125",
          "status_dev_done"            : "0",
          "status_ready_for_uat"       : "0",
          "status_waiting_for_go_live" : "0",
          "status_done"                : "10126",
          "status_cancelled"           : "0",
          "effort_start"               : "10125",
          "effort_end"                 : "10126"
        }
      }
    }
  },
  created () {
    
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      window.setInterval(() => {
        if (this.lastupdate == null)
          this.update_since = "Out Date";
        else
          this.update_since = "Last update: " + moment(this.lastupdate).fromNow();
      }, 1000);
      window.setInterval(() => {
        this.loadProjectUpdate();
      }, 30000);
    });
    this.loadProject();

    for (var i = 0; i > -10; i--) {
      let d = moment().add(i, 'W');
      this.task_hour.weeks.push(
        {
          week: d.isoWeek(),
          start: d.startOf('isoWeek').format('YYYY-MM-DD'),
          end: d.endOf('isoWeek').add(-2, 'days').format('YYYY-MM-DD'),
        }
      );
    }
  },
  beforeDestroy() { 
      window.removeEventListener('resize', this.onResize); 
  },
  methods: {
    onResize() {
        this.windowHeight = window.innerHeight;
        this.windowWidth = window.innerWidth;
    },
    buildTaskObject(e) {
      const project = e.key.split("-")[0];
      const status_mapping = this.c[project];
      let status_list = [];
      let remark_list = [];
      e.changelog.histories.forEach(h => {
        h.items.filter(i => i.field === 'status').forEach(i => {
          status_list.push({"status":i.to, "status_string":i.toString, "time": h.created, "author": h.author});
        });
        h.items.filter(i => i.fieldId === status_mapping.remark).forEach(i => {
          remark_list.push({"data":i, "time": h.created, "author": h.author});
        });
      });
      if (status_list.length == 0) {
        status_list.push({"status":e.fields.status.id, "time":e.fields.created, "author":e.fields.reporter})
      }
      status_list = status_list.sort((a, b) => (a.time > b.time) ? 1 : ((b.time > a.time) ? -1 : 0));

      return {
        key             : e.key,
        project         : project,
        changelog       : status_list,
        fields          : e.fields,
        status_time     : this.getContribution(status_list, e.fields[status_mapping.actual_man_hour] ?? 0, status_mapping),
        actual_man_hour : e.fields[status_mapping.actual_man_hour],
        pm_man_hour     : e.fields[status_mapping.pm_man_hour],
        requester       : e.fields[status_mapping.requester] ?? "",
        system_module   : e.fields[status_mapping.system_module] ?? "",
        customer        : e.fields[status_mapping.customer] ?? "",
        go_live_date    : e.fields[status_mapping.go_live_date] ?? "",
        remark          : remark_list
      };
    },
    postUpdate() {
      this.non_epic_task_list = this.task_list.filter(e => e.fields.issuetype.name != 'Epic');
      this.open_task_list = this.non_epic_task_list.filter(e => e.fields.issuetype.name != 'Epic' && e.fields.status.name != 'Cancelled' && e.fields.status.name != 'Done');
      this.task_category.requester = this.task_list.map(e => e.requester).filter((e, i, a) => e !== "" && a.indexOf(e) === i).sort((a, b) => a.localeCompare(b));
      this.task_category.system_module = this.task_list.map(e => e.system_module).filter((e, i, a) => e !== "" && a.indexOf(e) === i).sort((a, b) => a.localeCompare(b));
      this.task_category.customer = this.task_list.map(e => e.customer).filter((e, i, a) => e !== "" && a.indexOf(e) === i).sort((a, b) => a.localeCompare(b));
      this.task_category.member = this.task_list.filter(e => e.fields.assignee != null).map(e => e.fields.assignee.displayName).filter((e, i, a) => e !== "" && a.indexOf(e) === i).sort((a, b) => a.localeCompare(b));
      let contribution_list = this.task_list.filter(e => Object.keys(e.status_time.contribution_map).length > 0);

      let pending_day_stat_temp = {};
      this.open_task_list.forEach(e => {
        let d = this.isShowByStatusAndTime(e);
        let k = e.fields.priority.name + e.fields.status.name + (d > 14 ? '14+' : d);
        if (!pending_day_stat_temp[k]) {
          pending_day_stat_temp[k] = [];
        }
        pending_day_stat_temp[k].push(e);
      });
      this.pending_day_stat = pending_day_stat_temp;

      let task_members = {};
      let task_data_member = {};
      this.task_category.member.forEach(e => {
        this.task_hour.weeks.forEach(w => {
          let data = this.getContributionManHour(contribution_list, w.week, e, 'assignee');
          if (Object.keys(data.task_list).length > 0) {
            task_data_member[w.week + e] = data;
            task_members[e] = e;
          }
        });
      });
      this.task_hour.members = Object.keys(task_members).sort();
      this.task_hour.data_members = task_data_member;

      let task_requester = {};
      let task_data_requester = {};
      this.task_category.requester.forEach(r => {
        this.task_hour.weeks.forEach(w => {
          let data = this.getContributionManHour(contribution_list, w.week, r, 'requester');
          if (Object.keys(data.task_list).length > 0) {
            task_data_requester[w.week + r] = data;
            task_requester[r] = r;
          }
        });
      });
      this.task_hour.requesters = Object.keys(task_requester).sort();
      this.task_hour.data_requesters = task_data_requester;

      let task_customer = {};
      let task_data_customer = {};
      this.task_category.customer.forEach(c => {
        this.task_hour.weeks.forEach(w => {
          let data = this.getContributionManHour(contribution_list, w.week, c, 'customer');
          if (Object.keys(data.task_list).length > 0) {
            task_data_customer[w.week + c] = data;
            task_customer[c] = c;
          }
        });
      });
      this.task_hour.customers = Object.keys(task_customer).sort();
      this.task_hour.data_customers = task_data_customer;
    },
    async loadProject() {
      let that = this;
      let local_task_list = [];
      let local_changelog_list = [];
      let callback = function(data) {
        local_task_list = local_task_list.concat(data.map(e => that.buildTaskObject(e)));
        local_changelog_list = local_changelog_list.concat(that.loadChangeLog(data));
      };

      this.loading = true;

      let projectList = Object.keys(this.c);
      for (let i = 0; i < projectList.length; i++) {
        let p = that.c[projectList[i]];
        await this.loadJiraTicket("project = " + projectList[i], Object.keys(p).map(e => p[e]).filter(e => e.startsWith('customfield_')).concat(this.baseFields), callback, 0, [], ["changelog"]);
      }
      this.task_list = local_task_list;
      this.changelog_list = local_changelog_list.sort((a, b) => a.created.localeCompare(b.created)).reverse();
      this.$nextTick(() => {
        this.postUpdate();
      });
      this.lastupdate = new Date();
      this.loading = false;
    },
    async loadProjectUpdate() {
      if (this.loading === true) {
        return;
      }
      let that = this;
      let callback = function(data) {
        // lookup the existing issue
        if (data.length == 0) {
          return;
        }
        let new_list = data.map(e => that.buildTaskObject(e))
        that.task_list.forEach(e => {
          if (new_list.filter(n => n.key === e.key).length === 0) {
            new_list.push(e)
          }
        });
        that.task_list = new_list;

        let new_changelog_list = that.loadChangeLog(data);
        that.changelog_list.forEach(e => {
          if (new_changelog_list.filter(n => n.id === e.id).length === 0) {
            new_changelog_list.push(e)
          }
        });
        that.changelog_list = new_changelog_list.sort((a, b) => a.created.localeCompare(b.created)).reverse();
      };

      let projectList = Object.keys(this.c);
      for (let i = 0; i < projectList.length; i++) {
        let p = that.c[projectList[i]];
        await this.loadJiraTicket("project = " + projectList[i] + " AND updated >= -5m", Object.keys(p).map(e => p[e]).filter(e => e.startsWith('customfield_')).concat(this.baseFields), callback, 0, [], ["changelog"]);
      }
      this.$nextTick(() => {
        this.postUpdate();
      });
      this.lastupdate = new Date();
    },
    async loadJiraTicket(jql, fields, callback, startAt, fullList, expand) {
      try {
        const response = await fetcher(apis.jiraSearch, FetchMethod.POST, {jql:jql, startAt:startAt, maxResults:100, fields:fields, expand:expand});
        const issues = JSON.parse(response);
        this.total = issues.total;
        this.progress = fullList.length;
        fullList = fullList.concat(issues.issues);
        if (issues.maxResults <= issues.issues.length) {
          await this.loadJiraTicket(jql, fields, callback, startAt + issues.issues.length, fullList, expand);
        } else {
          callback(fullList);
        }
      } catch (err) {
        console.error(err)
      }
    },
    loadChangeLog(data) {
      let temp_list = [];
      data.forEach(e => {
        e.changelog.histories.filter(c => c.author.displayName !== 'Automation for Jira' && moment().diff(moment(c.created, "YYYY-MM-DD HH:mm:ss")) <= 2 * 24 * 60 * 60 * 1000).forEach(c => {
          let items = c.items.filter(i => !['Rank'].includes(i.field)).map(i => ({field: i.field, from: i.fromString, to: i.toString}));
          if (items.length > 0) {
            temp_list.push({
              id: c.id * 1,
              key: e.key,
              author: c.author.displayName,
              created: c.created,
              items: items
            });
          }
        });
      });
      return temp_list;
    },
    getContribution(changelog, actual_man_hour, status_mapping) {
      let requirement         = changelog.filter(h => (h.status === status_mapping.status_requirement))
      let to_do               = changelog.filter(h => (h.status === status_mapping.status_to_do))
      let in_progress         = changelog.filter(h => (h.status === status_mapping.status_in_progress))
      let dev_done            = changelog.filter(h => (h.status === status_mapping.status_dev_done))
      let ready_for_uat       = changelog.filter(h => (h.status === status_mapping.status_ready_for_uat))
      let waiting_for_go_live = changelog.filter(h => (h.status === status_mapping.status_waiting_for_go_live))
      let done                = changelog.filter(h => (h.status === status_mapping.status_done))

      let start_status        = changelog.filter(h => (h.status === status_mapping.effort_start))
      let end_status          = changelog.filter(h => (h.status === status_mapping.effort_end))

      let diff = null;
      let diff_hour = null;
      let start_cal_time = null;
      let end_cal_time = null;
      let contribution = [];
      let contribution_map = {};
      if (start_status.length > 0 && end_status.length > 0) {
        start_cal_time = new Date(start_status[0].time.substring(0, 19).replace('T', ' '));
        end_cal_time   = new Date(end_status[end_status.length - 1].time.substring(0, 19).replace('T', ' '));

        if (start_cal_time.getHours() < 9) {
          start_cal_time.setHours(8, 30, 0);
        } else if (start_cal_time.getHours() >= 18) {
          //start_cal_time.setTime(start_cal_time.getTime() + 24 * 60 * 60 * 1000);
          start_cal_time.setHours(17, 45, 0);
        }
        if (start_cal_time.getDay() === 0) { // Sunday
          start_cal_time.setTime(start_cal_time.getTime() - 2 * 24 * 60 * 60 * 1000);
          start_cal_time.setHours(17, 45, 0);
        } else if (start_cal_time.getDay() === 6) { // sat
          start_cal_time.setTime(start_cal_time.getTime() - 24 * 60 * 60 * 1000);
          start_cal_time.setHours(17, 45, 0);
        }

        if (end_cal_time.getHours() < 9) {
          // end_cal_time.setTime(end_cal_time.getTime() - 24 * 60 * 60 * 1000);
          end_cal_time.setHours(9, 15, 0);
        } else if (end_cal_time.getHours() >= 18) {
          end_cal_time.setHours(18, 30, 0);
        }
        if (end_cal_time.getDay() === 0) {
          end_cal_time.setTime(end_cal_time.getTime() + 24 * 60 * 60 * 1000);
          end_cal_time.setHours(9, 15, 0);
        } else if (end_cal_time.getDay() === 6) {
          end_cal_time.setTime(end_cal_time.getTime() + 2 * 24 * 60 * 60 * 1000);
          end_cal_time.setHours(9, 15, 0);
        }

        diff = Math.round((end_cal_time - start_cal_time) / 1000 / 60 / 60 * 100) / 100;

        diff_hour += (new Date(start_cal_time)).setHours(9, 0, 0) - start_cal_time;
        diff_hour += end_cal_time - (new Date(end_cal_time)).setHours(18, 0, 0);

        contribution.push({date: moment(start_cal_time).format('YYYY-MM-DD'), time: (new Date(start_cal_time)).setHours(9, 0, 0) - start_cal_time});
        contribution.push({date: moment(end_cal_time).format('YYYY-MM-DD'),   time: end_cal_time - (new Date(end_cal_time)).setHours(18, 0, 0)});
        for (let d = new Date(start_cal_time.getFullYear(), start_cal_time.getMonth(), start_cal_time.getDate(), 9, 0, 0); d <= end_cal_time; d.setDate(d.getDate() + 1)) {
          if (d.getDay() > 0 && d.getDay() < 6) {
            diff_hour += 9 * 60 * 60 * 1000;
            contribution.push({date: moment(d).format('YYYY-MM-DD'), time: 9 * 60 * 60 * 1000});
          }
        }

        let total = 0;
        contribution.forEach(e => {
          if (!contribution_map[e.date]) {
            contribution_map[e.date] = {task_time:0, actual_man_hour:0};
          }
          contribution_map[e.date].task_time += e.time;
          total += e.time;
        });
        // if (actual_man_hour) {
          Object.keys(contribution_map).forEach(k => {
            contribution_map[k].actual_man_hour = (actual_man_hour * contribution_map[k].task_time) / total;
          });
        //}
      }
      return {
        requirement         : requirement.length > 0         ? requirement[0].time.substring(0, 19).replace('T', ' ') : null,
        to_do               : to_do.length > 0               ? to_do[to_do.length - 1].time.substring(0, 19).replace('T', ' ') : null,
        in_progress         : in_progress.length > 0         ? in_progress[0].time.substring(0, 19).replace('T', ' ') : null,
        dev_done            : dev_done.length > 0            ? dev_done[dev_done.length - 1].time.substring(0, 19).replace('T', ' ') : null,
        ready_for_uat       : ready_for_uat.length > 0       ? ready_for_uat[0].time.substring(0, 19).replace('T', ' ') : null,
        waiting_for_go_live : waiting_for_go_live.length > 0 ? waiting_for_go_live[waiting_for_go_live.length - 1].time.substring(0, 19).replace('T', ' ') : null,
        done                : done.length > 0                ? done[done.length - 1].time.substring(0, 19).replace('T', ' ') : null,
        start_cal_time      : start_cal_time != null         ? moment(start_cal_time).format('YYYY-MM-DD HH:mm:ss') : null,
        end_cal_time        : end_cal_time != null           ? moment(end_cal_time).format('YYYY-MM-DD HH:mm:ss') : null,
        diff                : diff,
        diff_hour           : Math.round(diff_hour / 1000 / 60 / 60 * 100) / 100,
        contribution_map    : Object.keys(contribution_map).sort().reduce(function (result, key) {result[key] = contribution_map[key]; return result;}, {})
      };
    },
    getContributionManHour(task_list, week, compare_value, field_to_compare) {
      let total = 0;
      let r = {};
      task_list.filter(e => {
        if (field_to_compare === 'assignee' && (e.fields.assignee == null || e.fields.assignee.displayName !== compare_value))
          return false;
        if (field_to_compare === 'requester' && e.requester !== compare_value)
          return false;
        if (field_to_compare === 'customer' && e.customer !== compare_value)
          return false;
        // if (!e.actual_man_hour)
        //  return false;
        return true;
      }).forEach(e => {
        let task_total = 0;
        let in_week = 0;
        Object.keys(e.status_time.contribution_map).forEach(k => {
          if (moment(k).isoWeek() == week) {
            task_total += e.status_time.contribution_map[k].actual_man_hour;
            in_week ++;
          }
        });
        if (task_total > 0) {
          total += task_total;
          r[e.key] = Math.round(task_total * 100) / 100;
        } else if (in_week > 0) {
          r[e.key] = "-";
        }
      });
      return {total: Math.round(total * 100) / 100, task_list: r};
    },
    loadInfo(ticket) {
      let t = this.task_list.filter(e => e.key == ticket);
      if (t.length > 0) {
        this.selectedTask = t[0];
        this.$refs['modal-info'].show()
      }
    },
    getSinceNow(t) {
      return moment(t.substring(0, 19).replace("T", " "), "YYYY-MM-DD HH:mm:ss").fromNow()
    },
    getAge(t) {
      let d = moment().diff(moment(t, "YYYY-MM-DD HH:mm:ss"));
      // return Math.floor(d / 60 / 60 / 1000 / 24) + " 天 " + moment.utc(d - d / 60 / 60 / 1000 / 24).format("HH:mm:ss");
      return Math.floor(d / 60 / 60 / 1000 / 24) + " d";
    },
    getAgeWithStatus(n) {
      if (n.fields.status.name === 'To Do') {
        return this.getAge(n.status_time.to_do);
      } else if (n.fields.status.name === 'Requirement') {
        return this.getAge(n.status_time.requirement);
      } else if (n.fields.status.name === 'In Progress') {
        return this.getAge(n.status_time.in_progress);
      } else if (n.fields.status.name === 'Dev Done') {
        return this.getAge(n.status_time.dev_done);
      } else if (n.fields.status.name === 'Ready for UAT') {
        return this.getAge(n.status_time.ready_for_uat);
      } else if (n.fields.status.name === 'UAT Fail') {
        return "???";
      } else if (n.fields.status.name === 'Waiting for Go Live') {
        return this.getAge(n.status_time.waiting_for_go_live);
      }
      return "xxx";
    },
    isShowByStatusAndTime(n) {
      let ld = 0;
      if (n.fields.status.name === 'To Do') {
        ld = Math.floor(moment().diff(moment(n.status_time.to_do, "YYYY-MM-DD HH:mm:ss")) / 60 / 60 / 1000 / 24);
      } else if (n.fields.status.name === 'Requirement') {
        ld = Math.floor(moment().diff(moment(n.status_time.requirement, "YYYY-MM-DD HH:mm:ss")) / 60 / 60 / 1000 / 24);
      } else if (n.fields.status.name === 'In Progress') {
        ld = Math.floor(moment().diff(moment(n.status_time.in_progress, "YYYY-MM-DD HH:mm:ss")) / 60 / 60 / 1000 / 24);
      } else if (n.fields.status.name === 'Dev Done') {
        ld = Math.floor(moment().diff(moment(n.status_time.dev_done, "YYYY-MM-DD HH:mm:ss")) / 60 / 60 / 1000 / 24);
      } else if (n.fields.status.name === 'Ready for UAT') {
        ld = Math.floor(moment().diff(moment(n.status_time.ready_for_uat, "YYYY-MM-DD HH:mm:ss")) / 60 / 60 / 1000 / 24);
      } else if (n.fields.status.name === 'UAT Fail') {
        
      } else if (n.fields.status.name === 'Waiting for Go Live') {
        ld = Math.floor(moment().diff(moment(n.status_time.waiting_for_go_live, "YYYY-MM-DD HH:mm:ss")) / 60 / 60 / 1000 / 24);
      }
      return ld;
      // return ld === d || (ld > 14 && d === 15);
    }
  },
  watch: {
  },
  filters: {
  }
}
</script>

<style>
</style>
